import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { createStructuredSelector } from "reselect";
import validator from "validator";
import {
  signUpStart,
  stateClearAfterTask,
} from "./../../redux/user/user.actions";
import {
  selectSignUpMessage,
  selectSignupLoader,
} from "./../../redux/user/user.selectors";

const RegistrationForm = ({
  signUpStart,
  signUpmessage,
  stateClear,
  selectSignupLoader,
  handleCloseLoginPopup,
  search_type,
}) => {
  const [t] = useTranslation("common");
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const currentLocation = useLocation();
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    phoneno: "",
    // country_code: "",
    email: "",
    password: "",
    confirmpassword: "",
    referralcode:
      searchParams.get("referral_code") == null
        ? ""
        : searchParams.get("referral_code"),
    termsandConditions: false,
  });

  const [eyes, setEyes] = useState({
    password: false,
    confirmpassword: false,
  });
  const [userDataError, setuserDataError] = useState({
    firstnameErr: "",
    lastnameErr: "",
    // countrycodeErr: "",
    phonenoErr: "",
    emailErr: "",
    passwordErr: "",
    conformpasswordErr: "",
    referralcodeErr: "",
    termsmessage: "",
  });
  const [countryCode, setCountryCode] = React.useState("+966");
  const [countryCodeErr, setCountryCodeErr] = React.useState("");

  const navigate = useNavigate();
  const handletermspolicy = (e) => {
    if (e.target.checked) {
      setUserData({
        ...userData,
        termsandConditions: true,
      });
      setuserDataError({
        ...userDataError,
        termsmessage: "",
      });
    } else {
      setUserData({
        ...userData,
        termsandConditions: false,
      });
    }
  };

  /*** Country Code Selection   ***/
  const handleCountryCodeChange = (value, data, event, formattedValue) => {
    if (data.dialCode == "") {
    } else {
      setCountryCode(formattedValue);
      // setCountryCode("");
      setCountryCodeErr("");
    }
  };

  const handleKeyUp = (e) => {
    const englishReg = /^[a-zA-Z\s]*$/;

    if (e.target.name === "firstname") {
      const trimmedValue = e.target.value.trim();

      if (!englishReg.test(trimmedValue)) {
        setuserDataError({
          ...userDataError,
          firstnameErr: "English only, Please",
        });
      } else {
        setuserDataError({
          ...userDataError,
          firstnameErr: "",
        });
      }

      setUserData({
        ...userData,
        [e.target.name]: trimmedValue,
      });
    }

    if (e.target.name === "lastname") {
      const trimmedValue = e.target.value.trim();
      if (!englishReg.test(trimmedValue)) {
        setuserDataError({
          ...userDataError,
          lastnameErr: "English only, Please",
        });
      } else {
        setuserDataError({
          ...userDataError,
          lastnameErr: "",
        });
      }
      setUserData({
        ...userData,
        [e.target.name]: trimmedValue,
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name == "firstname") {
      setuserDataError({
        ...userDataError,
        firstnameErr: "",
      });
    } else if (e.target.name == "lastname") {
      setuserDataError({
        ...userDataError,
        lastnameErr: "",
      });
    } else if (e.target.name == "phoneno") {
      setuserDataError({
        ...userDataError,
        phonenoErr: "",
      });
    } else if (e.target.name == "email") {
      setuserDataError({
        ...userDataError,
        emailErr: "",
      });
    } else if (e.target.name == "password") {
      setuserDataError({
        ...userDataError,
        passwordErr: "",
      });
    } else {
      setuserDataError({
        ...userDataError,
        conformpasswordErr: "",
      });
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const regex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
    if (userData.firstname == "") {
      setuserDataError({
        ...userDataError,
        firstnameErr: t(
          "loginAndRegisterPage.validationMsg.PleaseEnterYourFirstName"
        ),
      });
      return;
    } else if (userData.lastname == "") {
      setuserDataError({
        ...userDataError,
        lastnameErr: t(
          "loginAndRegisterPage.validationMsg.PleaseEnterYourLastName"
        ),
      });
      return;
    } else if (countryCode == "") {
      setCountryCodeErr(
        t("loginAndRegisterPage.validationMsg.PleaseSelectYourcountrycode")
      );
      return;
    } else if (userData.phoneno == "") {
      setuserDataError({
        ...userDataError,
        phonenoErr: t(
          "loginAndRegisterPage.validationMsg.PleaseEnterYourPhoneNumber"
        ),
      });
      return;
    } else if (isNaN(userData.phoneno)) {
      setuserDataError({
        ...userDataError,
        phonenoErr: t(
          "loginAndRegisterPage.validationMsg.PleaseEnteronlyNumber"
        ),
      });
      return;
    } else if (userData.phoneno.length < 10 || userData.phoneno.length > 10) {
      setuserDataError({
        ...userDataError,
        phonenoErr: t(
          "loginAndRegisterPage.validationMsg.digitnumberallowonly10"
        ),
      });
      return;
    } else if (!validator.isEmail(userData.email)) {
      setuserDataError({
        ...userDataError,
        emailErr: t(
          "loginAndRegisterPage.validationMsg.PleaseEnteraValidEmailAddress"
        ),
      });
      return;
    } else if (userData.password.length < 8) {
      setuserDataError({
        ...userDataError,
        passwordErr: t(
          "loginAndRegisterPage.validationMsg.Atleast8CharactersAreRequired"
        ),
      });
      return;
    } else if (userData.password !== userData.confirmpassword) {
      setuserDataError({
        ...userDataError,
        conformpasswordErr: t(
          "loginAndRegisterPage.validationMsg.PasswordandConfirmPasswordNotSame"
        ),
      });
      return;
    } else if (userData.termsandConditions === false) {
      setuserDataError({
        ...userDataError,
        termsmessage: t(
          "loginAndRegisterPage.validationMsg.PleaseAcceptTermsAndConditions"
        ),
      });
    } else {
      const data = {
        first_name: userData.firstname,
        last_name: userData.lastname,
        email: userData.email,
        contact_no: userData.phoneno,
        password: userData.password,
        country_code: countryCode,
        referral_code: userData.referralcode,
      };
      signUpStart(data);
    }
  };
  useEffect(() => {
    if (signUpmessage != null) {
      if (signUpmessage.success == true) {
        setUserData({
          firstname: "",
          lastname: "",
          country_code: "",
          phoneno: "",
          email: "",
          password: "",
          confirmpassword: "",
          referralcode: "",
          termsandConditions: false,
        });
        if (currentLocation.pathname == "/sign-up") {
          navigate("/");
        } else {
        }
      } else {
      }
      stateClear();
    }
  }, [JSON.stringify(signUpmessage)]);

  const handleeyeshowhide = (number) => {
    if (number == 2) {
      setEyes({
        ...eyes,
        confirmpassword: !eyes.confirmpassword,
      });
    } else {
      setEyes({
        ...eyes,
        password: !eyes.password,
      });
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="form50">
          <Form.Group controlId="formBasicFirstname">
            <Form.Label>{t("loginAndRegisterPage.FirstName")} *</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("loginAndRegisterPage.FirstName")}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              value={userData.firstname}
              name="firstname"
            />
            <Form.Text className="text-muted errorformmessage">
              {userDataError.firstnameErr}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicLastname">
            <Form.Label>{t("loginAndRegisterPage.LastName")} *</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("loginAndRegisterPage.LastName")}
              name="lastname"
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              value={userData.lastname}
            />
            <Form.Text className="text-muted errorformmessage">
              {userDataError.lastnameErr}
            </Form.Text>
          </Form.Group>
        </div>
        <div className="form50 countryCodePhoneDiv">
          <Form.Group controlId="formBasicPhone">
            <Form.Label>{t("loginAndRegisterPage.CountryCode")} *</Form.Label>
            <PhoneInput
              // country={"us"}
              name="country_code"
              inputProps={{
                name: "country_code",
                readOnly: true,
              }}
              placeholder={"+91"}
              enableSearch={true}
              excludeCountries={[
                "cu",
                "ru",
                "kp",
                "ua",
                "ir",
                "sd",
                "ss",
                "sy",
              ]}
              className="countryCodeInput"
              value={countryCode}
              onChange={handleCountryCodeChange}
            />

            <Form.Text className="text-muted errorformmessage">
              {countryCodeErr}
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPhone">
            <Form.Label>
              {t("loginAndRegisterPage.phoneNumberText")} *
            </Form.Label>
            <Form.Control
              type="tel"
              className="form-control"
              placeholder={t("loginAndRegisterPage.PhoneNumber")}
              name="phoneno"
              value={userData.phoneno}
              onChange={handleChange}
              maxLength={10}
            />
            <Form.Text className="text-muted errorformmessage">
              {userDataError.phonenoErr}
            </Form.Text>
          </Form.Group>
        </div>
        <Form.Group controlId="formBasicEmailForRegister">
          <Form.Label>{t("loginAndRegisterPage.Email")} *</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("loginAndRegisterPage.Emailaddress")}
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
          <Form.Text className="text-muted errorformmessage">
            {userDataError.emailErr}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicPasswordForRegisterPassword">
          <Form.Label>{t("loginAndRegisterPage.Password")} *</Form.Label>
          <div className="passwordsec">
            <Form.Control
              type={eyes.password ? "text" : "password"}
              placeholder={t("loginAndRegisterPage.Password")}
              name="password"
              value={userData.password}
              onChange={handleChange}
            />
            {eyes.password ? (
              <AiOutlineEye
                onClick={() => {
                  handleeyeshowhide();
                }}
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => {
                  handleeyeshowhide();
                }}
              />
            )}
          </div>
          <Form.Text className="text-muted errorformmessage">
            {userDataError.passwordErr}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicPasswordForRegisterConfirmPassword">
          <Form.Label>{t("loginAndRegisterPage.ConfirmPassword")} *</Form.Label>
          <div className="passwordsec">
            <Form.Control
              type={eyes.confirmpassword ? "text" : "password"}
              name="confirmpassword"
              placeholder={t("loginAndRegisterPage.ConfirmPassword")}
              value={userData.confirmpassword}
              onChange={handleChange}
            />
            {eyes.confirmpassword ? (
              <AiOutlineEye
                onClick={() => {
                  handleeyeshowhide(2);
                }}
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => {
                  handleeyeshowhide(2);
                }}
              />
            )}
          </div>
          <Form.Text className="text-muted errorformmessage">
            {userDataError.conformpasswordErr}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicReferral">
          <Form.Label>{t("loginAndRegisterPage.ReferralCode")}</Form.Label>
          <div className="passwordsec">
            <Form.Control
              type="text"
              placeholder={t("loginAndRegisterPage.ReferralCode")}
              name="referralcode"
              value={userData.referralcode}
              onChange={handleChange}
            />
          </div>
        </Form.Group>
        <Form.Group className="form-checkbox" controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            checked={userData.termsandConditions}
            onChange={handletermspolicy}
            label=""
          />
          <div className="TC-message-container">
            {t("loginAndRegisterPage.Iagreeallthe")}{" "}
            <Link to="/terms&conditions" target="_blank">
              {t("loginAndRegisterPage.termsandconditions")}
            </Link>{" "}
            <span className="px-1"> {t("loginAndRegisterPage.&")}</span>{" "}
            <Link to="/privacy-policy" target="_blank">
              {t("loginAndRegisterPage.privacypolicy")}
            </Link>
          </div>
          <Form.Text className="text-muted errorformmessage">
            {userDataError.termsmessage}
          </Form.Text>
        </Form.Group>
        <input type="hidden" id="zc_gad" name="zc_gad" value="Revsoc_Youtube_engagement_11Apr2024"/>
        
        <Button variant="primary" type="submit" className="formsubmit">
          {selectSignupLoader ? (
            <Spinner animation="border" variant="light" />
          ) : (
            t("loginAndRegisterPage.SIGNUP")
          )}
        </Button>
      </Form>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  signUpmessage: selectSignUpMessage,
  selectSignupLoader: selectSignupLoader,
});
const mapDispatchToProps = (dispatch) => ({
  signUpStart: (data) => dispatch(signUpStart(data)),
  stateClear: () => dispatch(stateClearAfterTask()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
